require("../style/reset.less");
require("../style/base.less");
require("./darkMode");
require("./slider");

import { onReady } from "./utils"

/**
 * layout UI/UX
 */
let layoutAction = () => {
  /* Header menu handler */
  const touchEvent = 'click' // (window.ontouchstart === null) ? 'touchend' : 'click';
  let menuWrapper = document.querySelector('.header-wrapper .menu-wrapper');
  let menuUL = document.querySelector('.header-wrapper ul');
  let menuBtn = document.getElementsByClassName('menu-btn')[0];

  // Open menu
  menuBtn.addEventListener(touchEvent, e => {
    menuWrapper.classList.toggle('active');
  })

  // Prevent menu from being closed when menu clicked
  menuUL.addEventListener(touchEvent, e => {
    e.stopPropagation()
  })

  // Close menu when the outside is clicked
  menuWrapper.addEventListener(touchEvent, e => {
    e.stopPropagation()
    menuWrapper.classList.toggle('active');
  })
}

onReady(layoutAction)