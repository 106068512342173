let themeToggleBtn = null;

let isDarkmode = () => {
  return JSON.parse(localStorage.getItem('darkmode')) === true;
}

let toggleTheme = () => {
  let isDark = isDarkmode();

  setTheme(!isDark)
  localStorage.setItem('darkmode', JSON.stringify(!isDark))
  document.body.classList.toggle('darkmode')
}

let setTheme = (isDark) => {
  themeToggleBtn.checked = isDark;
  let enableStyle = isDark ? "#style-dark" : "#style-light";
  let disableStyle = !isDark ? "#style-dark" : "#style-light";

  document.querySelector(enableStyle).removeAttribute("disabled")
  // To prevent flickering by removing style, use setTimeout
  setTimeout(() => {
    document.querySelector(disableStyle).setAttribute("disabled", "disabled")
  }, 100)

  if (isDark) {
    // activateDarkMode();
  } else {
  }
}

(function () {
  themeToggleBtn = document.getElementById('theme-toggle');
  themeToggleBtn.addEventListener('change', toggleTheme);
  setTheme(isDarkmode());
})()
