require("../style/slider.less");

import { onReady } from "./utils"


const INTERVAL = 2700; // ms

/**
 * Set periodic job for moving animation
 * @param {Element} slider 
 */
let applySliderAnimation = (slider) => {
    let widthUnit = slider.clientWidth;

    setInterval(() => {
        let items = slider.getElementsByClassName("slider-item");

        // Copy the leaving item to be appended to the end of the slider.
        let leavingItem = items[0].cloneNode(true);
        slider.appendChild(leavingItem);

        // Move items left
        for (let idx = 0; idx < items.length; idx++) {
            items[idx].style.left = (idx - 1) * widthUnit + "px"
        }

        // Remove the first item that is already copied to the end.
        setTimeout(() => {
            slider.removeChild(items[0]);
        }, INTERVAL - 500);

    }, INTERVAL);
}

/**
 * Scroll to the prev/next image.
 * @param {Event} e 
 * @param {Number} direction -1 for left, 1 for right 
 * @param {Element} slider 
 */
let showNextImage = (e, direction, slider) => {
    slider.scrollTo({
        top: 0,
        left: slider.scrollLeft + slider.clientWidth * direction,
        behavior: 'smooth'
    });
}

let initializeSlider = () => {
    // Set css `left` attributes for each item, ~and activate auto-slide~.
    Array.from(document.getElementsByClassName("slider")).forEach(slider => {
        let widthUnit = slider.clientWidth;

        let items = slider.getElementsByClassName("slider-item");
        for (let idx = 0; idx < items.length; idx++) {
            items[idx].style.left = idx * widthUnit + "px"
        }

        slider.classList.add("active");
        // applySliderAnimation(slider);
    })

    // Add events for clicking left/right arrow button.
    Array.from(document.getElementsByClassName("slide-btn")).forEach(btn => {
        let direction = btn.classList.contains("left") ? -1 : 1;
        let slider = direction === -1 ? btn.nextElementSibling : btn.previousElementSibling;
        btn.addEventListener('click', (e) => {
            showNextImage(e, direction, slider);
        })
    })
}

onReady(initializeSlider)
